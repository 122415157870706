import { http_post_method, http_get_method, http_download_method } from '../utils/common-http'; 
import { InspectionReport as InspectionReportType } from '../types/InspectionReport';

export const SaveInspectionReportData = async (InspectionReportData: InspectionReportType) => {
    const response = await http_post_method(`api/InspectionReport`, InspectionReportData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetInspectionReportList = async () => {
    const response = await http_get_method(`api/InspectionReport`, {  });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetInspectionReportData = async (pk_InspectionReportId: number) => {
    const response = await http_get_method(`api/InspectionReport/GetInspectionReportData`, { pk_InspectionReportId: pk_InspectionReportId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetExistingIRforItem = async (pk_ItemId: number) => {
    const response = await http_get_method(`api/InspectionReport/GetExistingIRforItem`, { pk_ItemId: pk_ItemId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GenerateReport = async (pk_InspectionReportId: number) => {
    const filename = "INSP_";
    await http_download_method(`api/InspectionReport/GenerateInspectionReportRDL`, { pk_InspectionReportId: pk_InspectionReportId }, filename, 'pdf');
}