import { http_download_method, http_get_method } from '../utils/common-http'; 

export const StatementOfSales = async (pk_CustomerId: number, startDate: Date, endDate: Date) => {
    const response = await http_get_method(`api/Reports/StatementOfSales`, { pk_CustomerId: pk_CustomerId, startDate: startDate, endDate: endDate });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const StatementOfSummary = async (pk_CustomerId: number, startDate: Date, endDate: Date) => {
    const response = await http_get_method(`api/Reports/StatementOfSummary`, { pk_CustomerId: pk_CustomerId, startDate: startDate, endDate: endDate });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const StatementOfStock = async (pk_CustomerId: number, startDate: Date, endDate: Date) => {
    const filename = "StatementOfStock_";
    await http_download_method(`api/Reports/StatementOfStock`, { pk_CustomerId: pk_CustomerId, startDate: startDate, endDate: endDate }, filename, 'pdf');
}

export const GetItemHistoryDetails = async (pk_ItemId: number, pk_CustomerId: number) => {
    const response = await http_get_method(`api/Reports/GetItemHistoryDetails`, { pk_ItemId: pk_ItemId, pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const StatementOfStockExcel = async (pk_CustomerId: number, startDate: Date, endDate: Date) => {
    const filename = "StatementOfStock_";
    await http_download_method(`api/Reports/StatementOfStockExcel`, { pk_CustomerId: pk_CustomerId, startDate: startDate, endDate: endDate }, filename, 'xlsx');
}