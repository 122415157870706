import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from './constants';
import { useSelector } from "react-redux";
import { saveAs } from 'filesaver';

export const http_authenticate = async (endpoint: string, paramBody: any) => {
  const response = <AxiosResponse>await axios.post(BASE_URL + endpoint, paramBody)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
}

export const http_get_method = async (endpoint: String, param: any) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  const response = <AxiosResponse>await axios.get(BASE_URL + endpoint, {
    params: param,
  }).then((res) => {
    return res;
  });
  return response;
}

export const http_download_method = async (endpoint: string, param: any, FileName, type) => {
  const response = <AxiosResponse>await axios.get(BASE_URL + endpoint, {
    params: param,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    },
    responseType: 'blob',
  })
    .then(function (res) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([res.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        FileName + get_date_prefix() + '.' + type,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

export const http_post_method = async (endpoint: string, paramBody: any) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  const response = <AxiosResponse>await axios.post(BASE_URL + endpoint, paramBody)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
}

export const get_date_prefix = () => {
  const today = new Date();
  const res = today.getTime();
  return res;
}