import { http_post_method, http_get_method, http_download_method } from '../utils/common-http'; 
import { Invoice as InvoiceType } from '../types/Invoice';

export const SaveInvoiceData = async (InvoiceData: InvoiceType) => {
    const response = await http_post_method(`api/Invoice`, InvoiceData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetInvoiceData = async () => {
    const response = await http_get_method(`api/Invoice`, {  });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteInvoice = async (pk_InvoiceId: number) => {
    const response = await http_get_method(`api/Invoice/DeleteInvoice`, { pk_InvoiceId: pk_InvoiceId });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GenerateReport = async (pk_InvoiceId: number) => {
    const filename = "INV_";
    await http_download_method(`api/Invoice/GenerateReport`, { pk_InvoiceId: pk_InvoiceId }, filename, 'pdf');
}

export const GetLatestWorkorderForCustomer = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/WorkOrder/GetLatestWorkorderForCustomer`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}