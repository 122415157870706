import { http_post_method, http_get_method, http_download_method } from '../utils/common-http'; 
import { DeliveryChallan as DeliveryChallanType } from '../types/DeliveryChallan';

export const SaveDeliveryChallanData = async (DeliveryChallanData: DeliveryChallanType) => {
    const response = await http_post_method(`api/DeliveryChallan`, DeliveryChallanData);
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetDeliveryChallanData = async (pk_DeliveryChallanId: string) => {
    const response = await http_get_method(`api/DeliveryChallan/GetDeliveryChallanData`, { pk_DeliveryChallanId: pk_DeliveryChallanId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetDCListForWO = async (pk_WorkOrderId: string) => {
    const response = await http_get_method(`api/DeliveryChallan/GetDCListForWO`, { pk_WorkOrderId: pk_WorkOrderId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const DeleteDeliveryChallan = async (pk_DeliveryChallanId: number) => {
    const response = await http_get_method(`api/DeliveryChallan/DeleteDeliveryChallan`, { pk_DeliveryChallanId: pk_DeliveryChallanId });
    
    if(response != null && response){
        let data = response.data;
        return data;
    }
}

export const GetDeliveryChallanList = async () => {
    const response = await http_get_method(`api/DeliveryChallan`, { });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GetDCForInvoice = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/DeliveryChallan/GetDCForInvoice`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}

export const GenerateReport = async (pk_DeliveryChallanId: number) => {
    const filename = "DC_";
    await http_download_method(`api/DeliveryChallan/GenerateReport`, { pk_DeliveryChallanId: pk_DeliveryChallanId }, filename, 'pdf');
}

export const GetWorkOrderForDCList = async (pk_CustomerId: number) => {
    const response = await http_get_method(`api/DeliveryChallan/GetWorkOrderForDCList`, { pk_CustomerId: pk_CustomerId });
    
    if(response != null && response){
        let data = response.data;
        return JSON.parse(data.result);
    }
}